import { acceptHMRUpdate, defineStore } from 'pinia';
import api from '@/api';
import axios from '@/plugins/common/axios';
import formatDate from '@/plugins/format/date';
import useAppLogger from '@/composables/useAppLogger';

export const useStoreAppVersion = defineStore({
    id: 'app/version',

    state: () => ({
        data: {
            // data: useLocalStorage('pinia/version/data/data', null),
            // user: useLocalStorage('pinia/version/data/user', null),
            data: null,
            user: null,
        },
        loaded: false,
        cookie: { timestamp: null, date: null, version: null },
        vs: { timestamp: null, date: null, version: null },
        server: { timestamp: null, date: null, version: null },
        needReload: false,
        needReloadForce: false,
    }),

    getters: {
        dataVersion: state => state.data.data,
        userVersion: state => state.data.user,
    },

    actions: {
        setNeedReload(val: boolean) {
            this.needReload = val;
        },
        setNeedReloadForce(val: boolean) {
            this.needReloadForce = val;
        },
        async fetchVersion(callback: any) {
            const self = this;
            const url = api.index.version;
            await axios.get(url, {}, (result: any) => {
                // self.data.user = result.data.user;
                // self.data.data = result.data.data;

                // console.log(self.getDateFormat(new Date(self.data.user * 1000)));
                // console.log(self.getDateFormat(new Date(self.data.data * 1000)));

                callback(result.data);

                //
                // useLocalStorage('pinia/version/data/data', parseInt(result.data.data));
                // useLocalStorage('pinia/version/data/user', parseInt(result.data.user));
            }, () => {
                //
            });
        },
        async fetchVs(callback: any) {
            const self = this;

            // if (self.vs !== null && self.vs.timestamp !== undefined) {
            //     const now = new Date();
            //     const nowFloor = Math.floor(now.getTime() / 1000);
            //     const diff = nowFloor - self.vs.timestamp;
            //     if (diff < 60) { // 60 секунд
            //         return;
            //     }
            // }

            await axios.getLocal('/vs.json', {}, (result: any) => {
                useAppLogger().version('--------------- ' + result.version + ' ---------------');
                //
                // if (self.vs !== null && self.vs.version !== result.version) {
                //
                //     self.needReload = true;
                // }
                self.vs.version = result.version;
                self.vs.timestamp = result.timestamp;
                self.vs.date = formatDate().toString(new Date(result.timestamp * 1000));
                //
                // if (result.timestamp !== undefined) {
                //     self.setCurrentVs(result.timestamp);
                // }
                callback(result);

                // const date = new Date();
                // self.vs.timestamp = Math.floor(date.getTime() / 1000);
                // self.vs.date = self.getDateFormat(date);

                // console.log(new Date(), new Date(date.getTime()));
            }, () => {
                //
            });
        },
        setCookie(data: any) {
            this.cookie.version = data.version;
            this.cookie.timestamp = data.timestamp;
            this.cookie.date = formatDate().toString(new Date(data.timestamp * 1000));
        },
        setVs(data: any) {
            this.vs.version = data.version;
            this.vs.timestamp = data.timestamp;
            this.vs.date = formatDate().toString(new Date(data.timestamp * 1000));
        },
        setServer(data: any) {
            this.server.version = data.version;
            this.server.timestamp = data.timestamp;
            this.server.date = formatDate().toString(new Date(data.timestamp * 1000));
        },
        setData(data: any) {

        }
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useStoreAppVersion, import.meta.hot));
}
