import { useLocalStorage } from '@vueuse/core';
import { useCookie, useNuxtApp } from 'nuxt/app';
import { defineStore, acceptHMRUpdate } from 'pinia';
import api from '@/api';
import axios from '@/plugins/common/axios';
import useAppCookies from '@/plugins/cookies';
import { useStoreAppVersion } from '@/stores/app/version';
import { useStoreSystem } from '@/stores/system';

// interface State {
//     version: string|null;
// }

export const useStoreAppDataIndex = defineStore({
    id: 'app/data/index',

    state: () => ({
        version: {
            timestamp: null,
            date: null,
        },
        loaded: false,
        reload: false,
        pending: true,
        local: {
            config: null,
        },
        data: {
            config: null,
            developer: false,
            project: null,
            sidebar: null,
            header: {
                groups: {
                    fields: [],
                }
            },
            pages: {
                feeds: [],
                pages: [],
                groups: [],
                categories: [],
                group_categories: [],
            },
            articles: {
                main: [],
                right: [],
            },
            footer: {
                title: null,
                description: null,
                logo: null,
                sections: [],
            },
            titles: {},
            sections: {},
            status: {},
        },
    }),

    getters: {
    //
    },

    actions: {
        async fetchApiLocal(url: string, callback: any) {
            await axios.getLocal(url, {}, (result: any) => {
                callback(result);
            }, () => {
                //
            });
        },
        async fetchApiData(callback: any) {
            const self = this;
            self.pending = true;
            const url = api.index.data.index;
            await axios.get(url, {}, (result: any) => {
                self.pending = false;
                // self.loaded = true;
                // self.data = result.data;
                // localStorage.removeItem('pinia/app/data/index/data');
                // localStorage.removeItem('pinia/app/data/index/loaded');
                self.data = result.data;
                self.loaded = true;

                const system = useStoreSystem();
                system.setLoaded(true);

                const version = useStoreAppVersion();
                self.version.timestamp = version.dataVersion;

                callback(result.data);
            }, () => {
                //
            });
        },
        async fetch() {
            const self = this;
            self.pending = true;
            const url = api.index.data.index;
            await axios.get(url, {}, (result: any) => {
                self.pending = false;
                // self.loaded = true;
                // self.data = result.data;
                // localStorage.removeItem('pinia/app/data/index/data');
                // localStorage.removeItem('pinia/app/data/index/loaded');
                // localStorage.removeItem('pinia/app/data/index/version');
                self.data = result.data;
                this.setDataDefault();

                self.loaded = true;
                self.reload = false;

                const system = useStoreSystem();
                system.setLoaded(true);

                const version = useStoreAppVersion();
                self.version.timestamp = version.dataVersion;

                // useNuxtApp().setDataConfig(result.data.config);
                // console.log(useNuxtApp().getDataConfig());
                // cookies().setDataConfig(result.data.config);
                // useAppCookies().setDataConfig(result.data.config);
                // const config = useAppCookies().getDataConfig();
                // console.log(config);
                // setCookie();
                // useLocalStorage('pinia/app/data/index/data', result.data);
                // useLocalStorage('pinia/app/data/index/loaded', true);
                // useLocalStorage('pinia/app/data/index/version', val);
                // if (process.client) {
                //     localStorage.setItem(
                //         'pinia/app/data/index/version',
                //         val,
                //     );
                // }
            }, () => {
                //
            });
        },
        async fetchDataSidebar() {
            const self = this;
            self.pending = true;
            const url = api.index.data.sidebar;
            await axios.get(url, {}, (result: any) => {
                self.pending = false;
                self.data.sidebar = result.data.sidebar;
            }, () => {
                //
            });
        },
        setData(data: any) {
            this.pending = false;
            this.data = data;
            this.setDataDefault();
            this.loaded = true;

            const system = useStoreSystem();
            system.setLoaded(true);

            const version = useStoreAppVersion();
            this.version.timestamp = version.dataVersion;
        },
        setLocal(data: any) {
            this.pending = false;
            this.data = data;
            this.setDataDefault();
            this.loaded = true;

            const system = useStoreSystem();
            system.setLoaded(true);

            const version = useStoreAppVersion();
            this.version.timestamp = version.dataVersion;
        },
        setReload(val: boolean) {
            this.reload = val;
        },
        setDataDefault() {
            // useSeoMeta({
            //     title: this.data.config.META.title || '',
            // });
        }
    },
});
if (import.meta.hot) {
    import.meta.hot.accept(
        acceptHMRUpdate(useStoreAppDataIndex, import.meta.hot),
    );
}
